export interface ISensorValue {
    sensorId: string;
    userId: string;
    brandId: string;
    typeId: string;
    date: Date;
    value: number;
    units?: string;
}

export interface ISensorData {
    typeId: string
    lastValueDate: Date
}

export interface ISensorDataExtended extends ISensorData {
    lastValue: number
    units: string
}

export enum DeviceBrandId {
    //#region CHAMP
    WoePal = "9e62",
    WAQI = "8aaa",
    Flow = "105a",
    Fitbit = "1111",
    CapMedic = "tu76",
    //#endregion

    SakuraTech = "0ece",

    //#region A-IQ Ready
    inBiot = "0889",
    PolitoAndSAT = "ba11",
    //#endregion

    //#region SMILE
    AwarenessApp = "d521",
    DSS1 = "6be9",
    DSS2 = "4334",
    //#endregion

    //#region VETLLAR
    CEMIC = "720d",
    //#endregion
}

export enum DeviceBrandName {
    //#region CHAMP
    WoePal = "WoePal",
    WAQI = "WAQI",
    Flow = "Flow",
    Fitbit = "Fitbit",
    CapMedic = "CapMedic",
    //#endregion

    SakuraTech = "NVISION",

    //#region A-IQ Ready
    inBiot = "inBiot",
    PolitoAndSAT = "Polito and SAT",
    //#endregion

    //#region SMILE
    AwarenessApp = "Awareness App",
    DSS1 = "DSS1",
    DSS2 = "DSS2",
    //#endregion

    //#region VETLLAR
    CEMIC = "CEMIC",
    //#endregion
}

export enum SensorTypeId {
    HeartRate = "393a",
    BreathingRate = "344a",
    HRV = "884c",
    SPO2 = "04b8",
    PM1 = "0a51",
    PM10 = "9ab5",
    PM25 = "eacd",
    NO2 = "a372",
    VOC = "32c0",
    Pressure = "0a0e",
    Temperature = "d462",
    Humidity = "2e61",
    CO = "bfea",
    O3 = "a552",
    SO2 = "c951",
    PEF = "yo03",
    FEV1 = "g87i",
    PUFF = "p11d",
    AirQualityIndex = "f6e5",
    BreathingWave = "6853",
    CardiacWave = "4bcb",
    HeartbeatWave = "47f8",
    MovementWave = "9b85",
    CO2 = "0617",
    Formaldehyde = "7078",
    PM4 = "9e02",

    //#region A-IQ Ready
    Type = "5dc1",
    DrowsinessState = "2d83",
    Latitude = "1785",
    Longitude = "e7aa",
    Altitude = "7697",
    //#endregion

    //#region SMILE
    PHQ9Score = "c85b",
    GAD7Score = "36b6",
    AnxietyLevel = "2572",
    DepressionLevel = "e36d",
    //#endregion

    //#region VETLLAR
    FormaldehydeCounts = "92b4",
    FormaldehydeDiffCounts = "f33c",
    CO2Counts = "9e78",
    //#endregion
};

export enum SensorTypeName {
    HeartRate = "Heart rate",
    BreathingRate = "Breathing rate",
    HRV = "HRV",
    SPO2 = "SPO2",
    PM1 = "PM1",
    PM10 = "PM10",
    PM25 = "PM25",
    NO2 = "NO2",
    VOC = "VOC",
    Pressure = "Pressure",
    Temperature = "Temperature",
    Humidity = "Humidity",
    CO = "CO",
    O3 = "O3",
    SO2 = "SO2",
    PEF = "PEF",
    FEV1 = "FEV1",
    PUFF = "PUFF",
    AirQualityIndex = "AQI",
    BreathingWave = "Breathing wave",
    CardiacWave = "Cardiac wave",
    HeartbeatWave = "Heartbeat wave",
    MovementWave = "Movement wave",
    CO2 = "CO2",
    Formaldehyde = "Formaldehyde",
    PM4 = "PM4",

    //#region A-IQ Ready
    Type = "Type",
    DrowsinessState = "Drowsiness state",
    Latitude = "Latitude",
    Longitude = "Longitude",
    Altitude = "Altitude",
    //#endregion

    //#region SMILE
    PHQ9Score = "PHQ-9 score",
    GAD7Score = "GAD-7 score",
    AnxietyLevel = "Anxiety level",
    DepressionLevel = "Depression level",
    //#endregion

    //#region VETLLAR
    FormaldehydeCounts = "Formaldehyde counts",
    FormaldehydeDiffCounts = "Formaldehyde diff. counts",
    CO2Counts = "CO2 counts",
    //#endregion
};