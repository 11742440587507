import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetAnnotationsQuery,
  useGetSensorValuesMutation,
  usePostAnnotationMutation,
} from "features/api/apiSlice";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CHAMPTelemetries } from "models/enums/CHAMPTelemetries";
import { KORETelemetries } from "models/enums/KORETelemetries";
import { AIQReadyTelemetries } from "models/enums/AIQReadyTelemetries";
import TimeSeriesChart, {
  TimeSeriesAnnotation,
  TimeSeriesValue,
} from "components/charts/TimeSeriesChart";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IGetSensorValueRequest } from "models/api/requests/sensorvalues/IGetSensorValueRequest";
import "./PatientChart.css";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import AddAnnotationDialog from "components/dialogs/Annotations/AddAnnotationDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import { IUser, UserProviderId } from "models/IUser";
import { SMILETelemetries } from "models/enums/SMILETelemetries";
import { VETLLARTelemetries } from "models/enums/VETLLARTelemetries";

type RangeDate = {
  from: Date;
  to: Date;
};

interface Props {
  patientId: string,
  users: IUser[]
}

export function PatientChart({ patientId, users }: Props) {
  const { t } = useTranslation();
  const user = users!!.find(x => x.id == patientId)!!

  const [rangeDates, setRangeDates] = useState({
    from: moment().startOf("day").subtract(1, "days").toDate(),
    to: moment().endOf("day").toDate()
  } as RangeDate);
  const [searchParams] = useSearchParams();
  const [timeSeriesValues, setTimeSeriesValues] = useState<
    TimeSeriesValue[] | undefined
  >([]);
  const [timeSeriesAnnotations, setTimeSeriesAnnotations] = useState<
    TimeSeriesAnnotation[] | undefined
  >([]);
  const [getSensorValues, { isLoading: isGetSensorValuesLoading }] =
    useGetSensorValuesMutation();
  const [telemetries, setTelemetries] = useState<string[]>([]);
  const [sensors, setSensors] = useState<string[]>([]);

  const { data: annotations } = useGetAnnotationsQuery(
    user.id
      ? {
          userId: user.id,
          from: rangeDates.from.toISOString(),
          to: rangeDates.to.toISOString(),
        }
      : skipToken
  );

  const Telemetries = user?.provider === UserProviderId.KORE
  ? KORETelemetries
  : user?.provider === UserProviderId.AIQReady
  ? AIQReadyTelemetries
  : user?.provider === UserProviderId.SMILE
  ? SMILETelemetries
  : user?.provider === UserProviderId.VETLLAR
  ? VETLLARTelemetries
  : CHAMPTelemetries;


  useEffect(() => {
    const telemetriesQuery = searchParams.get("telemetries");
    if (telemetriesQuery) {
      const telemetriesParts = telemetriesQuery.split(";");
      const sensorsArray: string[] = telemetriesParts.map((telemetriesPart) => {
        const telemetryPart = telemetriesPart.split(":");
        const deviceId = telemetryPart[0];
        const telemetryId = telemetryPart[1];
        return `${user.id}:${deviceId}:${telemetryId}`;
      });
      setSensors(sensorsArray);
      setTelemetries(telemetriesParts);
    }
    const fromQuery = searchParams.get("from");
    const toQuery = searchParams.get("to");
    setRangeDates({
      from:
        fromQuery && toQuery
          ? moment(fromQuery, "YYYY-MM-DDTHH:mm:ssZ").toDate()
          : moment().startOf("day").subtract(1, "days").toDate(),
      to:
        fromQuery && toQuery
          ? moment(toQuery, "YYYY-MM-DDTHH:mm:ssZ").toDate()
          : moment().endOf("day").toDate()
    });
  }, [user.id, searchParams]);

  useEffect(() => {
    async function loadSensorValues() {
      if (user.id && sensors.length > 0) {
        let timeSeriesList: TimeSeriesValue[] = [];
        for (let sensor of sensors) {
          const request: IGetSensorValueRequest = {
            sensorId: sensor,
            from: rangeDates.from?.toISOString() ?? "",
            to: rangeDates.to?.toISOString() ?? "",
          };
          const response = await getSensorValues(request).unwrap();
          if (response.isSuccess) {
            const timeSeries: TimeSeriesValue[] = response.data.map((c) => {
              return {
                id: c.sensorId,
                name: getName(c.sensorId),
                date: new Date(c.date),
                value: c.value,
                units: c.units
              };
            });
            timeSeriesList = [...timeSeriesList, ...timeSeries];
          }
        }
        setTimeSeriesValues(timeSeriesList);
      } else {
        setTimeSeriesValues([])
      }
    }
    loadSensorValues();
  }, [user.id, sensors, rangeDates]);

  useEffect(() => {
    if (annotations && annotations.filter((c) => c.timelineDate).length > 0 && users) {
      const data = annotations.map((c) => {
        return {
          userRole: users!!.find(x => x.id == c.creationUserId)?.role,
          date: new Date(c.timelineDate),
          description: c.text,
        } as TimeSeriesAnnotation;
      });
      setTimeSeriesAnnotations(data);
    }
  }, [annotations, users]);

  useEffect(() => {
    if (telemetries.length > 0) {
      setSensors(
        telemetries.map((telemetry) => {
          const telemetryPart = telemetry.split(":");
          const deviceId = telemetryPart[0];
          const telemetryId = telemetryPart[1];
          return `${user.id}:${deviceId}:${telemetryId}`;
        })
      );
    } else {
      setSensors([])
    }
  }, [telemetries]);

  const getName = (sensorId: string) => {
    const sensorIdParts = sensorId.split(":");
    const telemetry = getKeyByValue(Telemetries, `${sensorIdParts[1]}:${sensorIdParts[2]}`);
    return `${telemetry}`;
  };

  const getKeyByValue = (obj: any, value: string) => {
    let result = "";
    Object.entries(obj).find(([k, v]) => {
      if (v === value) {
        result = k;
        return true;
      }

      return false;
    });
    return result;
  };

  const [showAddAnnotationDialog, setShowAddAnnotationDialog] = useState<boolean>(false);

  const handleOpenAddAnnotationDialog = () => {
    setShowAddAnnotationDialog(true)
  };

  const handleCloseAddAnnotationDialog = () => {
    setTimelineDate(null)
    setShowAddAnnotationDialog(false);
  };

  const [postAnnotation] = usePostAnnotationMutation();
  const handleAddAnnotation = async (timelineDate: string, text: string) => {
    await postAnnotation({userId: user.id!!, timelineDate, text})
      .unwrap()
      .then((payload) => {
        setShowAddAnnotationDialog(false);
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
    setSnackbarMessage(null)
  };

  const [timelineDate, setTimelineDate] = useState<Date|null>(null);
  const handleOpenAddAnnotationDialogWithTimelineDate = (timelineDate: Date) => {
    setTimelineDate(timelineDate)
    setShowAddAnnotationDialog(true)
  };

  return (
    <>
      <Grid
        container
        sx={{ width: "100%" }}
        spacing={{ xs: 0, md: 2 }}
        columns={{ xs: 12, sm: 12, md: 12 }}
        paddingTop={3}
      >
        <Grid container item wrap="nowrap" spacing={2}>
          <Grid item container wrap="nowrap" xs={true}>
            <FormControl sx={{ minWidth: "145px", marginRight: "15px" }}>
              <InputLabel id="telemetries-selector-label">{user.provider !== UserProviderId.SMILE ? t('sensors', 'Sensors') : t('features', 'Features')}</InputLabel>
              <Select
                labelId="telemetries-selector-label"
                id="telemetries-selector"
                multiple
                value={telemetries}
                onChange={(event: SelectChangeEvent<string[]>) => {
                  const {
                    target: { value },
                  } = event;
                  setTelemetries(value as string[]);
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected: any[]) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "nowrap", gap: 0.5 }}>
                      {selected.map((value, i) => (
                        <Chip sx={{maxHeight: 20}} key={i} label={getKeyByValue(Telemetries, value)} />
                      ))}
                    </Box>
                  );
                }}
                MenuProps={{
                  PaperProps: {
                    id: "telemetries-selector-itemlist",
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250
                    },
                  },
                }}
              >
                {Object.entries(Telemetries).map(([k, v], i) => (
                  <MenuItem key={k} value={v}>
                    {k}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: "Start", end: "Desktop end" }} adapterLocale="es">
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <DatePicker
                  label={t('from', 'From')}
                  value={dayjs(rangeDates.from)}
                  onChange={(newValue) => {
                    setRangeDates({
                      ...rangeDates,
                      from: moment(newValue?.toString())
                        .startOf("day")
                        .toDate()
                    });
                  }}
                  sx={{ width: '145px' }}
                />
                <DatePicker
                  label={t('to', 'To')}
                  value={dayjs(rangeDates.to)}
                  onChange={(newValue) => {
                    setRangeDates({
                      ...rangeDates,
                      to: moment(newValue?.toString())
                        .endOf("day")
                        .toDate()
                    });
                  }}
                  sx={{ width: '145px' }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          { user.provider !== UserProviderId.SMILE && user.provider !== UserProviderId.VETLLAR && <Grid item container alignItems="center" justifyContent='flex-end' xs="auto">
            <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAddAnnotationDialog} sx={{textTransform: 'none'}}>
              {t('add_annotation', 'Add annotation')}
            </Button>
          </Grid> }
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TimeSeriesChart
            showLoading={isGetSensorValuesLoading}
            values={timeSeriesValues}
            annotations={timeSeriesAnnotations}
            handleOpenAddAnnotationDialogWithTimelineDate={handleOpenAddAnnotationDialogWithTimelineDate}
          />
        </Grid>
      </Grid>

      {showAddAnnotationDialog && <AddAnnotationDialog openDialog={showAddAnnotationDialog} handleClose={handleCloseAddAnnotationDialog} handleAddAnnotation={handleAddAnnotation} timelineDate={timelineDate} />}

      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
};
